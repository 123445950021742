export enum SODComponents {
  COMPETITION_WINNERS_SHARE = "Winner's Share",
  COMPETITION_MARGIN_OF_VICTORY = "Margin of Victory",
  COMPETITION_UNCOMPETITIVE_SEATS = "Uncompetetive Seats",
  COMPETITION_UNCONTESTED_RACES = "Uncontested Races",
  PARTICIPATION_PRIMARY_ELECTION = "Primary",
  PARTICIPATION_GENERAL_ELECTION = "General",
  POLARIZATION_HOUSE = "House",
  POLARIZATION_SENATE = "Senate",
  REPRESENTATION_SEX = "Sex",
  REPRESENTATION_RACE = "Race",
  REPRESENTATION_NON_WHITE = "NonWhite",
  REPRESENTATION_FEMALE = "Female",
  TOTAL = "Total",
}

export enum SODSubComponents {
  REPRESENTATION_PARITY = "Parity",
  REPRESENTATION_POPULATION = "Population",
  REPRESENTATION_LEGISLATURE = "Legislature",
}

export enum SODCycles {
  YEAR_2012 = "2012",
  YEAR_2014 = "2014",
  YEAR_2016 = "2016",
  YEAR_2018 = "2018",
  YEAR_2020 = "2020",
  OVERALL = "Overall",
}

export enum SODMeasures {
  COMPETITION = "Competition",
  REPRESENTATION = "Representation",
  PARTICIPATION = "Participation",
  POLARIZATION = "Polarization",
  GRAND_TOTAL = "GrandTotal",
}

export enum SODGrade {
  GRADE_A_PLUS = "A+",
  GRADE_A = "A",
  GRADE_A_MINUS = "A-",
  GRADE_B_PLUS = "B+",
  GRADE_B = "B",
  GRADE_B_MINUS = "B-",
  GRADE_C_PLUS = "C+",
  GRADE_C = "C",
  GRADE_C_MINUS = "C-",
  GRADE_D_PLUS = "D+",
  GRADE_D = "D",
  GRADE_D_MINUS = "D-",
  GRADE_F_PLUS = "F+",
  GRADE_F = "F",
  GRADE_F_MINUS = "F-",
  GRADE_NA = "NA",
}
