import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import { generateUrlForStates } from '../../../../../../utils';
import BaseComponent from '../../../../../base/base-component';
import { SODMeasures } from '../../../../../global/global-enum';
import { closeSVG } from '../../../../../global/svg/close';
import Grade from '../../../../grade/grade';
import { GradeSize } from '../../../../grade/grade-enum';
import SvgIcon from '../../../../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../../../svg-icon/svg-icon-enum';
import { ISODMapChartTooltipBodyProperties } from './sod-map-chart-tooltip-body-interface';
import './sod-map-chart-tooltip-body.scss';

export default class SODMapChartTooltipBody extends BaseComponent<ISODMapChartTooltipBodyProperties> {
  public render(): ReactNode {
    const {
      currentStateData,
      competitionData,
      participationData,
      polarizationData,
      representationData,
      measure,
    } = this.props;

    const criteria =
      measure === SODMeasures.GRAND_TOTAL
        ? 'overall'
        : `${measure.toLocaleLowerCase()}`;
    return (
      <div className="sod-map-tooltip">
        <div className="sod-map-tooltip__title">
          {currentStateData.state}
          {this.isMobileDevice() && (
            <button
              type="button"
              aria-label="Close"
              onClick={(): void => {
                this.props.removeTooltipContent();
              }}
            >
              {closeSVG}
            </button>
          )}
        </div>

        <div className="sod-map-tooltip__body">
          <div
            className={`sod-map-tooltip__grade${
              measure === SODMeasures.GRAND_TOTAL ? ' grade--overall' : ''
            }`}
          >
            <Grade grade={currentStateData.grade} size={GradeSize.MEDIUM} />
            <div className="sod-map-tooltip__text">Overall</div>
          </div>
          <div
            className={`sod-map-tooltip__grade${
              measure === SODMeasures.PARTICIPATION ? ' grade--overall' : ''
            }`}
          >
            <Grade grade={participationData?.grade} size={GradeSize.MEDIUM} />
            <div className="sod-map-tooltip__text">Participation</div>
          </div>
          <div
            className={`sod-map-tooltip__grade${
              measure === SODMeasures.COMPETITION ? ' grade--overall' : ''
            }`}
          >
            <Grade grade={competitionData?.grade} size={GradeSize.MEDIUM} />
            <div className="sod-map-tooltip__text">Competition</div>
          </div>
          <div
            className={`sod-map-tooltip__grade${
              measure === SODMeasures.REPRESENTATION ? ' grade--overall' : ''
            }`}
          >
            <Grade grade={representationData?.grade} size={GradeSize.MEDIUM} />
            <div className="sod-map-tooltip__text">Representation</div>
          </div>
          <div
            className={`sod-map-tooltip__grade${
              measure === SODMeasures.POLARIZATION ? ' grade--overall' : ''
            }`}
          >
            <Grade grade={polarizationData?.grade} size={GradeSize.MEDIUM} />
            <div className="sod-map-tooltip__text">Polarization</div>
          </div>
        </div>

        <div
          className="sod-map-tooltip__footer"
          onTouchStart={(): void => {
            window.location.href = `/state/${generateUrlForStates(
              currentStateData.state
            )}/${criteria}`;
          }}
        >
          <Link
            to={`/state/${generateUrlForStates(
              currentStateData.state
            )}/${criteria}`}
          >
            Click to learn more
            <SvgIcon
              width="14"
              viewBox="0 0 14 10"
              paths={[
                {
                  command: SvgIconPathCommand.M9_ARROW_RIGHT,
                  fill: '#000',
                },
              ]}
            />
          </Link>
        </div>
      </div>
    );
  }
}
