import { graphql, Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import SODMapChart from '../../../components/charts/map/sod/sod-map-chart';
import ContainerBox from '../../../components/container-box/container-box';
import ShareContainer from '../../../components/share-container/share-container';
import SocialMetadata from '../../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../../components/social-metadata/social-metadata-enum';
import SvgIcon from '../../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../../components/svg-icon/svg-icon-enum';
import { Route } from '../../../global/route-enum';
import { IEmbeddedCriteriaMapProperties } from './criteria-map-interface';

export default class EmbeddedCriteriaMap extends BaseComponent<IEmbeddedCriteriaMapProperties> {
  protected declareTranslateCollection(): string {
    return 'criteriaMapTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const { measure, name } = pageContext;

    return (
      <Fragment>
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={this.translate(`${name}Title`)}
          description={this.translate(`${name}Description`)}
          imageResourceFileName={`embedded-${name}-map.png`}
        />
        <ContainerBox className="c-section--embed">
          <ShareContainer className="c-embed__wrap" shareName={`${name}-map`}>
            <div className="c-embed__box">
              <h2 className="c-score__title">
                {this.translate(`${name}Title`)}
              </h2>
              <SODMapChart data={data} measure={measure} />
            </div>
            <div className="c-embed__logo">
              <Link to={Route.HOME}>
                <SvgIcon
                  width="111"
                  viewBox="0 0 208 44"
                  paths={[
                    {
                      command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                      fill: '#0D0C0D',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_EAST,
                      fill: '#C7AFF0',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_WEST,
                      fill: '#4A1D96',
                    },
                  ]}
                />
              </Link>
            </div>
          </ShareContainer>
        </ContainerBox>
      </Fragment>
    );
  }
}

export const query = graphql`
  query GetEmbeddedCriteriaMapData($measure: String) {
    all: allSheetDataAllSodData(
      filter: {
        measure: { eq: $measure }
        cycle: { eq: "Overall" }
        component: { eq: "Total" }
      }
    ) {
      nodes {
        code
        state
        grade
      }
    }
  }
`;
