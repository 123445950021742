import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { SODGradeColor } from '../../global/grade-color-enum';
import { IGrade } from './grade-interface';
import './grade.scss';

export default class Grade extends BaseComponent<IGrade> {
  public render(): ReactNode {
    const { grade, size } = this.props;
    const sizeClassName = `c-grade--${size} c-grade-box`;
    const rootClassName = `c-grade ${sizeClassName}`;
    const symbolClassName = `${sizeClassName}__symbol`;
    return (
      <div
        className={rootClassName}
        // style={{
        //   backgroundColor: SODGradeColor[grade],
        // }}
      >
        <div className={symbolClassName}>{grade}</div>
        <div
          className="c-grade__bg"
          style={{ backgroundColor: SODGradeColor[grade] }}
        ></div>
      </div>
    );
  }
}
