import React, { Fragment, ReactChild, ReactNode } from 'react';
import { Geography } from 'react-simple-maps';
import { generateUrlForStates } from '../../../../../utils';
import BaseComponent from '../../../../base/base-component';
import { SODMeasures } from '../../../../global/global-enum';
import { SODGradeColor } from '../../../../global/grade-color-enum';
import { ICriteriaData } from '../../../../templates/criteria/criteria-interface';
import {
  ISODMapChartData,
  ISODMapChartProperties,
  ISODMapChartState,
} from './sod-map-chart-interface';
import SODMapChartTooltipBody from './tooltip-body/sod-map-chart-tooltip-body';
import MapChart from '../map-chart';

export default class SODMapChart extends BaseComponent<
  ISODMapChartProperties,
  ISODMapChartState
> {
  public constructor(properties: ISODMapChartProperties) {
    super(properties);

    this.state = {};
  }

  private findTooltipData(
    nodes: ISODMapChartData[],
    code: string
  ): ISODMapChartData {
    return nodes.find((node) => node.code === code);
  }

  private setTooltipContent(
    data: ICriteriaData,
    currentState: ISODMapChartData
  ): void {
    const {
      allParticipation: { nodes: allParticipationNodes },
      allCompetition: { nodes: allCompetitionNodes },
      allRepresentation: { nodes: allRepresentationNodes },
      allPolarization: { nodes: allPolarizationNodes },
    } = data;
    const { code } = currentState;
    const participationData: ISODMapChartData = this.findTooltipData(
      allParticipationNodes,
      code
    );
    const competitionData: ISODMapChartData = this.findTooltipData(
      allCompetitionNodes,
      code
    );
    const polarizationData: ISODMapChartData = this.findTooltipData(
      allPolarizationNodes,
      code
    );
    const representationData: ISODMapChartData = this.findTooltipData(
      allRepresentationNodes,
      code
    );

    this.setState({
      currentStateData: currentState,
      participationData,
      representationData,
      polarizationData,
      competitionData,
    });
  }

  private removeTooltipContent(): void {
    this.setState({
      currentStateData: undefined,
    });
  }

  public render(): ReactNode {
    const { measure, data, isTooltip } = this.props;
    const {
      currentStateData,
      competitionData,
      participationData,
      polarizationData,
      representationData,
    } = this.state;
    const {
      all: { nodes: allNodes },
      overallData,
    } = data;

    return (
      <MapChart
        isTooltip={isTooltip}
        tooltipBody={(): ReactChild => {
          return currentStateData ? (
            <SODMapChartTooltipBody
              currentStateData={currentStateData}
              competitionData={competitionData}
              participationData={participationData}
              polarizationData={polarizationData}
              representationData={representationData}
              measure={measure}
              removeTooltipContent={(): void => {
                this.removeTooltipContent();
              }}
            />
          ) : (
            ''
          );
        }}
      >
        {({ geographies }) =>
          geographies.map((geo) => {
            const currentOverallState = allNodes.find(
              (s) => s.state === geo.properties.name
            );
            if (overallData) {
              var currentOverallData = overallData.nodes.find(
                (s) => s.state === geo.properties.name
              );
            } else {
              var currentOverallData = allNodes.find(
                (s) => s.state === geo.properties.name
              );
            }

            return (
              <Fragment key={geo.rsmKey}>
                <Geography
                  geography={geo}
                  stroke="#fff"
                  onMouseEnter={(event): void => {
                    isTooltip &&
                      this.setTooltipContent(data, currentOverallData);
                    const rect = event.currentTarget.getBoundingClientRect();
                  }}
                  onMouseLeave={(): void => {
                    isTooltip && this.removeTooltipContent();
                  }}
                  onClick={(): void => {
                    if (!this.isMobileDevice()) {
                      const { name } = geo.properties;
                      window.location.href = `/state/${generateUrlForStates(
                        name
                      )}/${
                        measure === SODMeasures.GRAND_TOTAL
                          ? 'overall'
                          : measure.toLocaleLowerCase()
                      }`;
                    }
                  }}
                  onTouchStart={(ev): void => {
                    this.setTooltipContent(data, currentOverallData);
                  }}
                  style={{
                    default: {
                      fill: SODGradeColor[currentOverallState?.grade] ?? '#eee',
                      outline: 'none',
                    },
                    hover: {
                      fill: 'current',
                      outline: 'none',
                      cursor: 'pointer',
                      strokeWidth: 2,
                      opacity: 1,
                      position: 'relative',
                      zIndex: 9,
                    },
                    pressed: {
                      fill: '#E42',
                      outline: 'none',
                    },
                  }}
                />
              </Fragment>
            );
          })
        }
      </MapChart>
    );
  }
}
